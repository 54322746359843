// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.game-round {
  text-align: center;
  margin: 20px;
}

.timer {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.color-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}

.color-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #000;
  transition: transform 0.2s ease;
  cursor: pointer;
}

.color-button:hover {
  transform: scale(1.1);
}

.color-button.selected {
  border: 4px solid #fff;
  transform: scale(1.2);
}

.result {
  margin-top: 20px;
}

.color-indicator {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  vertical-align: middle;
}
`, "",{"version":3,"sources":["webpack://./src/components/GameRound.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,+BAA+B;EAC/B,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;AACxB","sourcesContent":[".game-round {\r\n  text-align: center;\r\n  margin: 20px;\r\n}\r\n\r\n.timer {\r\n  font-size: 1.2em;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.color-buttons {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  justify-content: center;\r\n  gap: 10px;\r\n  margin: 20px 0;\r\n}\r\n\r\n.color-button {\r\n  width: 60px;\r\n  height: 60px;\r\n  border-radius: 50%;\r\n  border: 2px solid #000;\r\n  transition: transform 0.2s ease;\r\n  cursor: pointer;\r\n}\r\n\r\n.color-button:hover {\r\n  transform: scale(1.1);\r\n}\r\n\r\n.color-button.selected {\r\n  border: 4px solid #fff;\r\n  transform: scale(1.2);\r\n}\r\n\r\n.result {\r\n  margin-top: 20px;\r\n}\r\n\r\n.color-indicator {\r\n  display: inline-block;\r\n  width: 40px;\r\n  height: 40px;\r\n  border-radius: 50%;\r\n  vertical-align: middle;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
