import React from 'react';
import '../App.css';

function FinalScores({ players }) {
  const sortedPlayers = players.sort((a, b) => b.score - a.score);
  const winner = sortedPlayers[0];

  return (
    <div className="container">
      <h2>Game Over!</h2>
      <h3>Winner: {winner.name} with {winner.score} points!</h3>
      <table>
        <thead>
          <tr>
            <th>Player</th>
            <th>Final Score</th>
          </tr>
        </thead>
        <tbody>
          {sortedPlayers.map((player, index) => (
            <tr key={index}>
              <td>{player.name}</td>
              <td>{player.score}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default FinalScores;
