import React from 'react';

const Leaderboard = ({ leaderboard }) => {
  // Sort the leaderboard by score in descending order
  const sortedLeaderboard = [...leaderboard].sort((a, b) => b.score - a.score);

  return (
    <div>
      <h2>Leaderboard</h2>
      <table>
        <thead>
          <tr>
            <th>Player</th>
            <th>Final Score</th>
          </tr>
        </thead>
        <tbody>
          {sortedLeaderboard.map((player, index) => (
            <tr key={index}>
              <td>{player.name}</td>
              <td>{player.score}</td> {/* Final score */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Leaderboard;
