import React, { useState, useEffect } from 'react';
import './GameRound.css'; // Import a CSS file for styling
import roundWinnerSound from '../assets/sounds/round-winner.mp3'; // Import sound

function GameRound({ round, colors, onSelectColor, result, players, socket, timer }) {
  const [selectedColor, setSelectedColor] = useState(null);
  const [sound] = useState(new Audio(roundWinnerSound)); // Initialize sound

  useEffect(() => {
    // Reset selected color when the round changes
    setSelectedColor(null);
  }, [round, socket]); 

  useEffect(() => {
    if (result) {
      sound.play(); // Play sound when the result is displayed
    }
  }, [result, sound]);

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    if (socket) {
      socket.emit('selectColor', color); // Emit the selected color to the server
    } else {
      console.error('Socket is null, unable to emit the selected color');
    }
  };

  // Filter players who got the color right
const correctPlayers = result && Array.isArray(result.players)
  ? result.players.filter(player => player.correct)
  : [];

  return (
    <div className="game-round">
      <h2>Round {round}</h2>
      <p className="timer">Time left: {timer} seconds</p>

      {colors && colors.length > 0 ? (
        <div className="color-buttons">
          {colors.map((color) => (
            <button
              key={color}
              onClick={() => handleColorSelect(color)}
              className={`color-button ${selectedColor === color ? 'selected' : ''}`}
              style={{
                backgroundColor: color,
                opacity: selectedColor ? (selectedColor === color ? 1 : 0.5) : 1,
              }}
              disabled={!!selectedColor} // Disable button after selection
            >
              {/* Remove color text and rely on button color */}
            </button>
          ))}
        </div>
      ) : (
        <p>No colors available to display.</p>
      )}

      {selectedColor && <p>You selected a color!</p>}

      {result && (
        <div className="result">
          <p>Correct color: <span style={{ backgroundColor: result.correctColor }} className="color-indicator"></span></p>
          <p>
            Players who got it right: {correctPlayers.map((player) => player.name).join(', ')}
          </p>
        </div>
      )}
    </div>
  );
}

export default GameRound;
