import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import JoinGame from './components/JoinGame';
import GameRound from './components/GameRound';
import FinalScores from './components/FinalScores';
import Leaderboard from './components/Leaderboard';

const socket = io('https://matri.averoft.in');

const containerStyle = {
    width: '100%',
    maxWidth: '500px',
    margin: '0 auto',
    background: 'white',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
};

const headerStyle = {
    textAlign: 'center',
    fontSize: '2rem',
    color: '#007bff',
    marginBottom: '20px',
    fontWeight: 'bold',
};
const backgroundStyle = {
  minHeight: '100vh',
  	paddingTop: '15px',
  background: 'linear-gradient(135deg, #ff9a9e 20%, #00d0f4 80%)', // Example gradient
  justifyContent: 'center',
  alignItems: 'center',
};

function App() {
  const [player, setPlayer] = useState(null);
  const [isHost, setIsHost] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [round, setRound] = useState(null);
  const [colors, setColors] = useState([]);
  const [roundResult, setRoundResult] = useState(null);
  const [players, setPlayers] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [hostColor, setHostColor] = useState(null);
  const [timer, setTimer] = useState(30); // Initialize timer
  const [isReconnecting, setIsReconnecting] = useState(false);

  useEffect(() => {
    /*const savedPlayer = localStorage.getItem('player');
    const savedHostStatus = localStorage.getItem('isHost');
    const savedToken = localStorage.getItem('token'); // Retrieve the token

    if (savedPlayer && savedToken) {
      const parsedPlayer = JSON.parse(savedPlayer);
      const isHost = savedHostStatus === true;
      const password = isHost ? prompt('Enter host password to rejoin:') : null;

      socket.emit('rejoinGame', { token: savedToken, password });

      setPlayer(parsedPlayer);
      setIsHost(isHost);
    }*/
	document.title = "Color Casino";
    socket.on('isHost', (hostStatus) => {
      setIsHost(hostStatus);
      //localStorage.setItem('isHost', JSON.stringify(hostStatus));
      console.log('Host Status:', hostStatus);
    });

    socket.on('playerJoined', ({ name, isHost }) => {
      console.log(`${name} has joined the game${isHost ? ' as the host' : ''}.`);
    });

    socket.on('gameStarted', ({ round, colors }) => {
      setGameStarted(true);
      setRound(round);
      setColors(colors);
      console.log('Game Started - Round:', round, 'Colors:', colors);
    });
	
	socket.on('timerUpdate', ({ timeLeft }) => {
		setTimer(timeLeft); // Update the timer state from the server
	});

	socket.on('timeUp', () => {
		const countdown = setInterval(() => {
		  setTimer((prevTimer) => {
			if (prevTimer <= 1) {
			  clearInterval(countdown); // Clear the interval when the timer reaches 0
					socket.emit('finishRound'); 

			  return 0;
			}
			return prevTimer - 1;
		  });
		}, 1000);
	});

socket.on('roundResult', ({ correctColor, players: updatedPlayers }) => {
    setPlayers(updatedPlayers); 
    setRoundResult({ correctColor, players: updatedPlayers });
});


    socket.on('nextRound', ({ round, colors }) => {
      setRound(round);
      setColors(colors);
      console.log('Next Round - Round:', round, 'Colors:', colors);
      setRoundResult(null);
    });

    socket.on('gameOver', (finalPlayers) => {
      setPlayers(finalPlayers);
      setGameOver(true);

    // Optionally, notify the user
    alert('The game has ended. Thank you for playing!');
    });

    socket.on('hostDisconnected', () => {
      alert('The host has disconnected. The game will restart if host reconnects.');
    });

    socket.on('hostColor', (color) => {
      setHostColor(color);
    });

    // New: Listen for game state on reconnect
    socket.on('gameState', (gameState) => {
      setRound(gameState.round);
      setColors(gameState.colors);
      setPlayers(gameState.players);
      setGameStarted(gameState.gameStarted);
      setRoundResult(gameState.roundResult);
      setGameOver(gameState.gameOver);
      setHostColor(gameState.hostColor);
	  
		if (!gameState.gameOver) {
			setTimer(gameState.timeLeft); // Reset timer to full duration
		}
		console.log(gameState.players);
		const updatedPlayer = gameState.players.find(player => player.token === socket.id);
		/*if (updatedPlayer) {
			const updatedPlayerData = { name: updatedPlayer.name, token: updatedPlayer.token }; // Update as necessary
			localStorage.setItem('player', JSON.stringify(updatedPlayerData));
			localStorage.setItem('token', updatedPlayer.token); // Update token if necessary
		}*/
    setIsReconnecting(true); // Set reconnect flag

		console.log(gameState.timeLeft);
	   if (gameState.gameOver) {
        alert('The game has ended. Please restart or wait for the host.');
		}
    });

    return () => {
      socket.off('isHost');
      socket.off('playerJoined');
      socket.off('gameStarted');
      socket.off('roundResult');
      socket.off('nextRound');
      socket.off('gameOver');
      socket.off('hostDisconnected');
      socket.off('hostColor');
      socket.off('gameState'); // Clean up the event listener
    };
  }, []);

useEffect(() => {
    let countdown;

    if (isReconnecting && timer > 0) {
        countdown = setInterval(() => {
            setTimer(prevTimer => {
                if (prevTimer <= 1) {
                    clearInterval(countdown);
                    socket.emit('finishRound'); // Emit finish when timer reaches 0
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);
    }

    return () => clearInterval(countdown); // Clean up the interval
}, [isReconnecting, timer]);

  const handleJoin = (name, isHost) => {
    const playerData = { name, token: socket.id }; // Save token as socket id
    setPlayer(playerData);
    //localStorage.setItem('player', JSON.stringify(playerData));
    //localStorage.setItem('token', socket.id); // Store token in local storage
    socket.emit('joinGame', { name, isHost });
  };

  const startGame = () => {
    console.log('Starting the game...');
    socket.emit('startGame');
  };

  const finishRound = () => {
    console.log('Finishing the round...');
	//setTimer();
	clearInterval(timer);
	
    socket.emit('finishRound');
  };

  const nextRound = () => {
    console.log('Moving to the next round...');
    socket.emit('nextRound');
  };

  const selectColor = (color) => {
    console.log('Selecting color:', color);
    socket.emit('selectColor', color);
  };

  const restartGame = () => {
    console.log('Restarting the game...');
    socket.emit('restartGame');
    localStorage.removeItem('player');
    localStorage.removeItem('isHost');
    localStorage.removeItem('token');
    setPlayer(null);
    setIsHost(false);
    setGameStarted(false);
    setRound(null);
    setColors([]);
    setRoundResult(null);
    setPlayers([]);
    setGameOver(false);
    setHostColor(null);
  };

const endGame = () => {
    console.log('Ending the game...');
    socket.emit('endGame');
    //localStorage.removeItem('player');
   // localStorage.removeItem('isHost');
   // localStorage.removeItem('token');
    setPlayer(null);
    setIsHost(false);
    setGameStarted(false);
    setRound(null);
    setColors([]);
    setRoundResult(null);
    setPlayers([]);
    setGameOver(false);
    setHostColor(null);
};

  return (
    <div style={backgroundStyle}>
	      <header style={headerStyle}>
        Color Casino
      </header>
      {!player ? (
        <JoinGame onJoin={handleJoin} socket={socket} />
      ) : (
        <div>
          <h1>Welcome, {player.name}!</h1>
          {isHost ? (
            <div style={containerStyle}>
              <button onClick={startGame} disabled={gameStarted || gameOver}>
                {gameStarted || gameOver ? 'Game in Progress' : 'Start Game'}
              </button>
              {gameStarted && round && !gameOver && (
                <div>
                  <h2>Selected Color: {hostColor}</h2>
                  <button onClick={finishRound}>
                    Finish Round
                  </button>
                  <button onClick={nextRound} disabled={!roundResult}>
                    Next Round
                  </button>
                </div>
              )}
              <button onClick={restartGame} style={{ marginTop: '20px', backgroundColor: 'orange' }}>
                Restart Game
              </button>
              <button onClick={endGame} style={{ marginTop: '10px', backgroundColor: 'red' }}>
                End Game
              </button>
            </div>
          ) : (
            <p>Waiting for the host to start the game...</p>
          )}
          {gameStarted && round && !gameOver && (
            <GameRound
              round={round}
              colors={colors}
              onSelectColor={selectColor}
              result={roundResult}
              players={players}
              socket={socket}
		      timer={timer} // Pass the timer to GameRound

            />
          )}
	
		<div style={containerStyle}>
		
            <Leaderboard leaderboard={players} /> 

		</div>	

        </div>
      )}
    </div>
  );
}

export default App;