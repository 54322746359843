import React, { useState } from 'react';

function JoinGame({ onJoin, socket }) {
  const [name, setName] = useState('');
  const [isHost, setIsHost] = useState(false);  // Add this line to define isHost state
  const [password, setPassword] = useState(''); // Add this line to define password state

  const handleJoin = () => {
    if (name.trim()) {
      // Emit joinGame event with name, isHost, and password
      socket.emit('joinGame', { name, isHost, password });
      onJoin(name, isHost); // Call the onJoin callback with the player's name and isHost status
    }
  };

  return (
    <div className="container">
      <h2>Join the Game</h2>
      <input
        type="text"
        placeholder="Enter your name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        style={{ padding: '10px', width: '100%', boxSizing: 'border-box' }}
      />
      <div>
        <label>
          <input
            type="checkbox"
            checked={isHost}
            onChange={(e) => setIsHost(e.target.checked)}
          />
          I am the host
        </label>
      </div>
      {isHost && (
        <input
          type="password"
          placeholder="Enter host password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ padding: '10px', width: '100%', boxSizing: 'border-box', marginTop: '10px' }}
        />
      )}
      <button onClick={handleJoin} style={{ marginTop: '10px' }}>
        Join Game
      </button>
    </div>
  );
}

export default JoinGame;
